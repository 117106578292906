import React from 'react'
import styled from 'styled-components'
import { SvgText, Typography, Flex } from '../../components'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import 'react-image-lightbox/style.css'
import media from '../../constants/mediaQueries'
import { motion } from 'framer-motion'
const ProjectHero = ({ data }) => {
	const { title, category, laptopImage, mobileImage, ipadImage } = data

	return (
		<SHero justifyContent="center" alignItems="center">
			<SSvgText>{title}</SSvgText>

			<SHeroContent>
				<div className="title">
					<Typography type="h1" textTransform="none" fontWeight="500">
						{title}
					</Typography>
					<Typography type="h2" textTransform="none" fontWeight="200" fontSize="text-md">
						{category}
					</Typography>
				</div>
				<div className="images">
					
						<GatsbyImage image={laptopImage} className="image laptop"/>
					
						<GatsbyImage image={mobileImage} className="image mobile" />
					
					
						<GatsbyImage image={ipadImage} className="image ipad" />
					
				</div>
			</SHeroContent>
		</SHero>
	)
}

export default ProjectHero

const SHero = styled(Flex)`
	position: relative;
	height: 100vh;
	background-color: #ff3d4d;
	background-image: linear-gradient(315deg, #ff3d4d 15%, #c13139 41%);
`
const SSvgText = styled(SvgText)`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	/* border: 11px solid black; */
`

const SHeroContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
	min-height: 100%;
	top: 0;
	left: 0;
	.title {
		text-align: center;
	}

	/* overflow: hidden; */
	.images {
		width: 500px;
		height: 400px;
		position: relative;

		.image {
			position: absolute;
			&.ipad {
				left: -50px;
				bottom: -20px;
				z-index: 1;
				width: 220px;
				/* width: 45%; */
			}
			&.laptop {
				left: 0;
				top: 0;
				z-index: 1;
				width: 600px;
			}
			&.mobile {
				right: -180px;
				bottom: -40px;
				z-index: 1;

				width: 400px;
			}
		}

		img {
			/* max-width: 300px; */
		}

		@media screen and (max-width: ${media.md820}) {
			.image {
				&.ipad {
					left: -30px;
					bottom: -80px;
				}
				&.laptop {
				}
				&.mobile {
					right: -140px;
					bottom: -100px;
				}
			}
		}

		@media screen and (max-width: ${media.sm576}) {
			.image {
				&.ipad {
					left: 15%;
					/* transform: translateX(-47%); */
					bottom: -50px;
					display: none;
				}
				&.laptop {
					left: 50%;
					transform: translateX(-48%);
					top: 0;
					z-index: 1;
					width: 450px;
				}
				&.mobile {
					right: -5%;
					bottom: -180px;
					z-index: 20000;
					/* display: none; */
				}
			}
		}
	}
`

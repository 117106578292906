import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Flex } from '../../components/'
import 'swiper/css/bundle'
import Lightbox from 'react-image-lightbox'
import media from '../../constants/mediaQueries'
import { useMediaQuery } from 'react-responsive'

const Slider = ({ data }) => {
	const { allImages } = data
	const [isOpenLightbox, setOpenLightbox] = useState(false)
	const [photoIndex, setPhotoIndex] = useState(0)
	const isMobileDevice = useMediaQuery({ query: `(max-width: ${media.sm576} )` })
	const isMediumScreen = useMediaQuery({ query: `(max-width: ${media.lg992} )` })
	const openLightBox = (index) => {
		setOpenLightbox(true)
		setPhotoIndex(index)
	}
	let slidesPerView = 5
	if (isMobileDevice) {
		slidesPerView = 1
	} else if (isMediumScreen) {
		slidesPerView = 3
	}
	return (
		<>
			{isOpenLightbox && (
				<Lightbox
					mainSrc={allImages[photoIndex].url}
					onCloseRequest={() => setOpenLightbox(false)}
					nextSrc={allImages[(photoIndex + 1) % allImages.length].url}
					prevSrc={allImages[(photoIndex + allImages.length - 1) % allImages.length].url}
					onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % allImages.length)}
					onMovePrevRequest={() => setPhotoIndex((photoIndex + allImages.length - 1) % allImages.length)}
					enableZoom={false}
				/>
			)}
			<SAllImages>
				<Swiper
					slidesPerView={slidesPerView}
					spaceBetween={3}
					modules={[Pagination, Autoplay]}
					autoplay={{
						delay: 2000,
						disableOnInteraction: false,
					}}
					loop={true}
					speed={2000}
					className="mySwiper"
				>
					{allImages.map((item, index) => {
						const image = getImage(item)
						return (
							<SwiperSlide key={index} onClick={() => openLightBox(index)} className="slide">
								<GatsbyImage image={image} />
							</SwiperSlide>
						)
					})}
				</Swiper>
			</SAllImages>
		</>
	)
}

export default Slider

const SAllImages = styled(Flex)`
	margin-top: var(--space-xxl);
	flex-wrap: wrap;

	.slide {
		height: 350px;
		width: 400px !important;
		/* border: 1px solid red; */
		display: flex;
		justify-content: center;
		margin: 1rem;
		cursor: pointer;
		img {
			border-radius: 5px;
			width: 400px !important;
			min-width: 100%;
			max-width: 100%;
			object-fit: cover !important;
			object-position: top top !important;
			height: 300px;
		}
		@media screen and (max-width: ${media.sm576}) {
			width: 100%;

			margin: 0;
			img {
				width: 100%;
			}
		}
	}
`

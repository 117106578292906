import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Layout, Nav, Typography, Flex, ThreeBackground, Seo } from '../../components/'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import options from '../../constants/contentfulRichTextOptions'
import 'react-image-lightbox/style.css'
import media from '../../constants/mediaQueries'
import ProjectHero from './ProjectHero'
import Slider from './Slider'
import Details from './Details'

const Project = ({ data }) => {
	const [edges] = data.allContentful2022Portfolio.edges
	const {
		title,
		creationDate,
		isCommercial,
		description,
		www,
		github,
		video,
		subtitle,
		heroImg,
		mobileImg,
		ipadImg,
		laptopImg,
		transparentImg,
		fullPageImg,
		images,
		tech,
		category,
		seoDescription,
	} = edges.node

	const mobileImage = getImage(mobileImg)
	const ipadImage = getImage(ipadImg)
	const laptopImage = getImage(laptopImg)
	const heroImage = getImage(heroImg)
	// const fullpageImage = getImage(fullPageImg)
	const videoImg = getImage(video)
	const mockupImage = getImage(transparentImg && transparentImg[0])
	console.log('====================================')
	console.log(mobileImg)
	console.log('====================================')
	let allImages = []
	allImages = [...images]

	return (
		<Layout>
			<Seo lang="pl" description={seoDescription && seoDescription} title={'/ ' + title} />
			<Nav bg="primary" />
			<ThreeBackground />
			<ProjectHero data={{ title, category, laptopImage, mobileImage, ipadImage }} />

			<SDescription className="container">
				<Flex justifyContent="space-between" className="article">
					<Flex className="header" direction="column">
						<Typography type="h3" textTransform="none" fontSize="text-lg" fontWeight="500" line="line" m="4rem 0">
							{title}
						</Typography>
						<Typography type="p" textTransform="none">
							{subtitle && subtitle}
						</Typography>
						<SContent>
							<Flex className="mainImages" justifyContent="space-between">
								<div className="imageContainer">
									<GatsbyImage image={videoImg ? videoImg : heroImage} className="heroImage" />
								</div>
								<GatsbyImage image={mockupImage} className="mockupImage" />
							</Flex>
							<div className="description">
								<Typography type="h3" textTransform="none" fontSize="text-lg" fontWeight="500" line="line">
									Opis i zastosowane rozwiązania
								</Typography>
								<Typography type="p" fontSize="text-md">
									{renderRichText(description, options)}
								</Typography>
							</div>
						</SContent>
					</Flex>

					<Details data={{ isCommercial, creationDate, category, www, github, tech }} />
				</Flex>
			</SDescription>
			{allImages && <Slider data={{ allImages }} />}
		</Layout>
	)
}

export const query = graphql`
	query ($slug: String!) {
		allContentful2022Portfolio(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					title
					subtitle
					category
					www
					github

					creationDate(formatString: "MMMM yyyy", locale: "pl")
					isCommercial
					seoDescription
					tech {
						name
						icon {
							gatsbyImageData(width: 100)
						}
					}
					description {
						raw
					}
					video {
						id
						gatsbyImageData
					}
					heroImg {
						id
						gatsbyImageData(width: 1100)
					}
					mobileImg {
						id
						gatsbyImageData
					}
					transparentImg {
						id
						gatsbyImageData
					}
					ipadImg {
						id
						gatsbyImageData
					}
					laptopImg {
						id
						gatsbyImageData
					}

					images {
						url
						gatsbyImageData
					}
					tech {
						id
					}
					slug
					id
				}
			}
		}
	}
`

export default Project

const SDescription = styled.article`
	min-height: 100vh;
	max-width: 1500px !important;
	background: linear-gradient(180deg, black 70%, transparent);
	z-index: -1;
	.header {
		width: 80%;
		.mainImages {
			width: 95%;
			margin-top: var(--space-xl);
			.imageContainer {
				width: 100%;
				.heroImage {
					margin-right: 1rem;
					max-height: 600px;
					img {
						object-position: 0% 0%;
					}
				}
			}
			img {
				border-radius: 5px;
			}

			.mockupImage {
				/* transform: translateY(-100px); */
			}

			@media screen and (max-width: ${media.xl1200}) {
				flex-direction: column;
				.mockupImage {
					width: 700px;
					/* transform: translateY(-100px); */
					align-self: center;
				}
			}
			@media screen and (max-width: ${media.sm576}) {
				.mockupImage {
					width: 120%;
				}
			}
		}
		@media screen and (max-width: ${media.lg992}) {
			width: 100%;

			.mainImages {
				width: 100%;
				.imageContainer {
					.heroImage {
						max-height: 400px;
					}
				}
			}
			h3 {
				margin-bottom: 1rem;
			}
		}
	}

	.description {
		width: 70%;
		padding: var(--space-xxl) 0;
		/* margin: 0 auto; */
		@media screen and (max-width: ${media.lg992}) {
			width: 100%;
			padding: var(--space-sm) 0;
			margin: 0;
		}
		@media screen and (max-width: ${media.md820}) {
			padding: 0;
			margin: 0;
		}
		/* height: 100px; */
	}

	@media screen and (max-width: ${media.lg992}) {
		.article {
			flex-direction: column;
		}
	}
`

const SContent = styled.div``

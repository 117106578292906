import React from 'react'
import { Layout, Nav, Typography, Flex } from '../../components/'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import media from '../../constants/mediaQueries'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
const Details = ({ data }) => {
	const { isCommercial, creationDate, category, www, github, tech } = data

	return (
		<SDetails className="details" direction="column">
			
			<Flex className="items" direction="column">
				<div className="item">
					<Typography type="small" textTransform="uppercase">
						wersja
					</Typography>
					<Typography type="h4" fontSize="text-sm">
						{isCommercial ? 'Produkcyjna' : 'Demo'}
					</Typography>
				</div>
				<div className="item">
					<Typography type="small" textTransform="uppercase">
						Data
					</Typography>
					<Typography type="h4" fontSize="text-sm">
						{creationDate}
					</Typography>
				</div>
				<div className="item">
					<Typography type="small" textTransform="uppercase">
						kategoria
					</Typography>
					<Typography type="h4" fontSize="text-sm">
						{category}
					</Typography>
				</div>

				{www && (
					<div className="item">
						<Typography type="small" textTransform="uppercase">
							www
						</Typography>
						<Typography type="linkOut" to={www}>
							{www}
						</Typography>
					</div>
				)}

				{github && (
					<div className="item">
						<Typography type="small" textTransform="uppercase">
							github
						</Typography>
						<Typography type="linkOut" to={github}>
							{github}
						</Typography>
					</div>
				)}
			</Flex>
			<div className="item">
				<Typography type="small" textTransform="uppercase">
					technologie
				</Typography>

				<Flex className="techItem">
					{tech.map((item, index) => {
						const image = getImage(item.icon)
						return (
							<div className="item" key={index}>
								<GatsbyImage image={image} />
								<Typography type="h4" fontSize="text-sm">
									{item.name}
								</Typography>
							</div>
						)
					})}
				</Flex>
			</div>
		</SDetails>
	)
}

export default Details

const SDetails = styled.div`
	width: 15%;
	min-width: 200px;
	margin-top: 5rem;

	.item {
		margin: var(--space-sm) 0;
		margin-bottom: 1rem;

		.techItem {
			flex-direction: column;
			margin: var(--space-xs) 0;
			.item {
				width: 100%;
				display: flex;
				align-items: center;
				h4 {
					/* margin-left: var(--space-md); */
				}
				img {
					width: 45px;
					height: 45px;
					margin-right: var(--space-sm);
					border-radius: 2px;
				}
			}
		}
	}

	@media screen and (max-width: ${media.lg992}) {
		display: flex;
		flex-direction: column;
		width: 100%;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		.items {
			display: flex;
			flex-direction: column;
			width: 100%;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 2rem;
			border-radius: 5px;
			border: 1px solid var(--gray-4);
			padding: var(--space-md) 0;
			margin: 0 0 var(--space-xxl) 0;
		}
		.item {
			padding: 0 var(--space-md);

			&:last-child {
				text-align: center;
			}

			&:last-child {
				width: 100%;
			}
			/* text-align: center; */
			.techItem {
				width: 100%;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				margin-top: var(--space-lg);
				.item {
					/* border: 1px solid red; */
					min-width: 100px;
					height: 120px;
					width: 15%;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					padding: 0;
					margin: 0;
					h4 {
						text-align: center;
						margin-left: var(--space-md);
						height: 30px;
						margin: 1rem 0;
					}
					img {
						padding: 0%;
						margin: 0;
						width: 50px;
						height: 50px;
						border-radius: 5px;
					}
					&:last-child {
						/* border: 1px solid red; */
						/* margin: 1rem auto; */
					}
				}
				/* flex-direction: row; */
			}
		}
	}
	@media screen and (max-width: ${media.sm576}) {
		justify-content: center;
		.item {
			text-align: center;

			&:last-child {
				margin: 0;
				padding: 0;
			}
			h4,
			a {
				font-size: var(--text-xs);
			}
		}
	}
`
